<template>
  <section class="">
    <div v-if="loading">
      <v-container class="container-custom">
        <v-row>
          <v-col>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-skeleton-loader type="image" class="banner-skeleton" />
            <v-skeleton-loader type="image" class="banner-skeleton" />
            <v-skeleton-loader type="image" class="banner-skeleton" />
            <v-skeleton-loader type="image" class="banner-skeleton" />
            <v-skeleton-loader type="image" class="banner-skeleton" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="!loading">
      <div class="headbanner" :style="`background-image:url(${bannerUrl});`">
        <v-container class="container-custom aboutbanner">
          <div class="row">
            <div class="col-md-7 col banner-para">
              <h1>{{ training.category.title }}</h1>

              <div
                v-html="training.results.description.slice(0, 650) + '...'"
                class="trainingDescrption"
              ></div>
            </div>
            <div class="col-md-5 col">
              <div class="formjoin">
                <h5>Join Our Class</h5>
                <v-form ref="form" v-model="valid" lazy-validation class="">
                  <v-text-field
                    v-model="fullName"
                    outlined
                    label="Full Name"
                    :rules="[(v) => !!v || 'Name is required']"
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    outlined
                    label="Email"
                    :rules="[
                      ((v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'),
                    ]"
                  ></v-text-field>
                  <v-text-field
                    v-model="phone"
                    outlined
                    label="Phone"
                    :rules="[
                      (v) => !!v || 'Phone is required',
                      (v) =>
                        (v && v.length <= 10) ||
                        'Phone must be less than 10 characters',
                    ]"
                  ></v-text-field>
                  <v-btn
                    type="button"
                    :loading="submitLoading"
                    class="primary"
                    @click="submitForm"
                    style="height: 40px"
                    >Enroll Now</v-btn
                  >
                </v-form>
              </div>
            </div>
          </div>
        </v-container>
      </div>
      <!-- Logo to be added on slider -->
      <v-container class="container-custom belowfold">
        <h4 v-if="recognizes.length > 0">Students are working At</h4>

        <slick
          ref="slick"
          :options="slickOptions"
          v-if="certifications.length > 0"
        >
          <div
            v-for="(data, index) in recognizes"
            :key="index"
            class="logoscompany"
          >
            <img :src="data.image" class="companyimg" alt="" />
          </div>
        </slick>

        <slick
          ref="slickRev"
          :options="slickOptions1"
          class="reverseSlide"
          v-if="certifications.length > 0"
        >
          <div
            v-for="(data, index) in recognizes"
            :key="index"
            class="logoscompany"
          >
            <img :src="data.image" class="companyimg" alt="" />
          </div>
        </slick>
      </v-container>
      <!-- Neco course features -->
      <v-container class="container-custom feturebox">
        <div class="logoscompany">
          <div
            class="featurecard"
            v-for="(data, index) in allTraining.category.attributes"
            :key="index"
          >
            <h5>{{ data.attributeKey }}</h5>
            <p>
              {{ data.attributeDesc }}
            </p>
          </div>
        </div>
      </v-container>

      <v-container
        class="container-custom belowfold"
        v-if="paticipants.length > 0"
      >
        <h4>Tools Covered</h4>
        <slick
          ref="slick"
          :options="slickOptions"
          v-if="paticipants.length > 0"
        >
          <div
            v-for="(data, index) in paticipants"
            :key="index"
            class="logoscompany"
          >
            <img :src="data.image" class="companyimg" alt="" />
          </div>
        </slick>
      </v-container>

      <div
        class="coursecontent"
        v-if="
          allTraining.results.courseContent &&
          allTraining.results.courseContent.length > 0
        "
        style="background-color: #4782da"
      >
        <v-container class="container-custom">
          <h4>Course Content</h4>
          <div class="courseconttentfaq">
            <div
              class="coursecontentcard"
              v-for="(data, index) in allTraining.results.courseContent"
              :key="index"
            >
              <div
                class="coursecontentcardin"
                @click="
                  faqActive != data.title
                    ? (faqActive = data.title)
                    : (faqActive = undefined)
                "
              >
                <v-icon v-if="faqActive != data.title"> mdi-plus </v-icon>
                <v-icon v-if="faqActive == data.title"> mdi-minus </v-icon>
                <h5>{{ data.title }}</h5>
              </div>

              <div
                v-if="faqActive == data.title"
                v-html="data.description"
              ></div>
            </div>
          </div>
        </v-container>
      </div>
      <div
        class="coursefeatures"
        v-if="
          allTraining.results.courseObjects &&
          allTraining.results.courseObjects.length > 0
        "
      >
        <v-container class="coursefeaturesinner container-custom">
          <div class="coursefeaturescontent">
            <h4>Course Objectives</h4>
            <ul>
              <li
                v-for="(data, index) in allTraining.results.courseObjects"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </div>
          <div
            class="coursefeaturescontent"
            v-if="
              allTraining.results.forWhom &&
              allTraining.results.forWhom.length > 0
            "
          >
            <h4>Who ought to sign up HR Training ?</h4>
            <ul>
              <li
                v-for="(data, index) in allTraining.results.forWhom"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </div>
        </v-container>
      </div>

      <div
        class="coursecontent"
        v-if="allTraining.results.faqs && allTraining.results.faqs.length > 0"
      >
        <v-container class="container-custom">
          <h4>Frequently Asked Question (FAQ)</h4>
          <div class="courseconttentfaq">
            <div
              class="coursecontentcard"
              v-for="(data, index) in allTraining.results.faqs"
              :key="index"
            >
              <div
                class="coursecontentcardin"
                @click="
                  faqActive != data.title
                    ? (faqActive = data.title)
                    : (faqActive = undefined)
                "
              >
                <v-icon v-if="faqActive != data.title"> mdi-plus </v-icon>
                <v-icon v-if="faqActive == data.title"> mdi-minus </v-icon>
                <h5>{{ data.title }}</h5>
              </div>

              <p v-if="faqActive == data.title">
                {{ data.description }}
              </p>
            </div>
          </div>
        </v-container>
      </div>
      <v-container
        class="container-custom belowfold"
        v-if="certifications.length > 0"
      >
        <h4>Placement Partners</h4>
        <slick
          ref="slick"
          :options="slickOptions"
          v-if="certifications.length > 0"
        >
          <div
            v-for="(data, index) in certifications"
            :key="index"
            class="logoscompany"
          >
            <img :src="data.image" class="companyimg" alt="" />
          </div>
        </slick>

        <slick
          ref="slickRev"
          :options="slickOptions1"
          class="reverseSlide"
          v-if="certifications.length > 0"
        >
          <div
            v-for="(data, index) in certifications"
            :key="index"
            class="logoscompany"
          >
            <img :src="data.image" class="companyimg" alt="" />
          </div>
        </slick>
      </v-container>
      <div
        class="coursebenefit"
        :style="`background-image:url(${benefitsUrl}); width:100%; background-size:cover; background-position:center; `"
        v-if="
          allTraining.results.benefits &&
          allTraining.results.benefits.length > 0
        "
      >
        <v-container class="container-custom coursebenefitinner">
          <h4><span>Benefit of </span> <span>training</span></h4>
          <ul>
            <li
              v-for="(data, index) in allTraining.results.benefits"
              :key="index"
            >
              {{ data }}
            </li>
          </ul>
        </v-container>
      </div>

      <v-container
        class="container-custom belowfold"
        v-if="testimonials.length > 0"
      >
        <div width="100%">
          <slick ref="slick" :options="slickOptionss">
            <v-col v-for="(dat, index) in testimonials" :key="index">
              <div class="iconText2">
                <div
                  class=""
                  style="
                    border: 1px solid #d8d8d8;
                    border-radius: 5px;
                    padding: 6px;
                    margin-bottom: 10px;
                  "
                >
                  <h5 v-if="dat.description">
                    {{ dat.description }}
                  </h5>
                </div>
                <video
                  v-if="dat.image"
                  :src="dat.image"
                  controls
                  height="200"
                  width="100%"
                  class="mb-5"
                  style="object-fit: contain"
                ></video>
                <div class="reviewImage" style="width: 100%">
                  <v-row class="reviewflex">
                    <v-col md="3">
                      <img :src="dat.profileImage" />
                    </v-col>
                    <v-col md="9">
                      <p>{{ dat.fullName }}</p>
                      <div class="innerstar">
                        <v-icon v-for="(rat, index) in dat.rating" :key="index"
                          >mdi-star</v-icon
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </slick>
        </div>
      </v-container>

      <div class="courseupcoming">
        <PaymentForm :percentage="percentage" />
      </div>
      <v-container
        class="container-custom belowfold"
        v-if="
          allTraining.trainingOthers && allTraining.trainingOthers.length > 0
        "
      >
        <v-row>
          <v-col md="12">
            <h4>more classes by necojobs</h4>
          </v-col>
          <v-col
            md="4"
            v-for="(data, index) in allTraining.trainingOthers"
            :key="index"
            class="py-0 cards"
          >
            <Searchboxtraining :training="data" class="jobslistBox" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-snackbar v-model="snackbar" color="success" bottom>
      Your enquiry has been sent successfully
    </v-snackbar>
  </section>
</template>

<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { mapActions, mapGetters } from "vuex";
import Searchboxtraining from "@/components/frontend/SearchboxTraining";
import PaymentForm from "@/components/PaymentForm";

export default {
  name: "About",
  components: {
    Slick,
    Searchboxtraining,
    PaymentForm,
  },
  data: () => ({
    dialog: false,
    bannerUrl: require("@/assets/courses/banner.jpg"),
    benefitsUrl: require("@/assets/courses/benefits.jpg"),
    faqActive: undefined,
    training: undefined,
    fullname: undefined,
    valid: true,
    snackbar: false,
    percentage: 100,
    slickOptionss: {
      slidesToShow: 3,
      arrows: true,
      slidesToScroll: 1,
      autoplay: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    },
    slickOptions: {
      slidesToShow: 8,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    },
    slickOptions1: {
      slidesToShow: 8,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 2000,

      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    },

    fullName: "",
    email: "",
    phone: "",
    fullNameUp: "",
    emailUp: "",
    phoneUp: "",

    shifts: [
      {
        name: "Morning Shift",
        value: "Morning Shift",
      },
      {
        name: "Day Shift",
        value: "Day Shift",
      },
      {
        name: "Evening Shift",
        value: "Evening Shift",
      },
    ],
    subject: "",
    loading: true,
    submitLoading: false,
    recognizes: [],
    paticipants: [],
    certifications: [],
    testimonials: [],
  }),
  methods: {
    ...mapActions([
      "getTrainingListingFrontend",
      "postRegistration",
      "getCompanyLogos",
      "getSingleTrainingFront",
      "getAssets",
      "sendEnquiry",
      "resetState",
    ]),
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        const data = {
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          subject: this.$route.params.slug,
          trainingId: this.allTraining.results._id,
        };
        await this.sendEnquiry(data);
        this.$refs.form.reset();

        this.submitLoading = false;
        this.snackbar = true;
      }
    },
    submitFormUpcoming() {
      if (this.$refs.formUpcoming.validate()) {
        this.$router.push(
          `/paymentprocess?fullName=${this.fullNameUp}&email=${
            this.emailUp
          }&phone=${this.phoneUp}&subject=${
            this.allTraining.results.trainingTitle
          }&trainingId=${this.allTraining.results._id}&slug=${
            this.$route.params.slug
          }&amount=${this.allTraining.results.price * (this.percentage / 100)}`
        );
      }
    },
  },

  metaInfo() {
    return {
      title:
        this.allTraining.results && this.allTraining.results.metaTitle
          ? this.allTraining.results.metaTitle
          : "Training provider in Nepal -necojobs / Nepal's #1training company",
      meta: [
        {
          name: "description",
          content:
            this.allTraining.results && this.allTraining.results.metaDescription
              ? this.allTraining.results.metaDescription
              : "Training provider in Nepal -necojobs / Nepal's #1training company",
        },
        {
          name: "keywords",
          content:
            this.allTraining.results && this.allTraining.results.metaKeywords
              ? this.allTraining.results.metaKeywords
              : "Course",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://www.necojobs.com.np/course/${this.$route.params.slug}`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "allTraining",
      "allTrainings",
      "allTrainingRegistration",
      "allCompanyLogos",
      "allAssets",
    ]),
  },

  async created() {
    this.resetState();

    await this.getSingleTrainingFront(this.$route.params.slug);
    await this.getCompanyLogos();
    this.training = this.allTraining;
    this.loading = false;

    await this.getAssets({
      title: "Recognized",
      connectedTo: this.allTraining.category._id,
    });

    this.recognizes = this.allAssets;

    await this.getAssets({
      title: "Participants",
      connectedTo: this.allTraining.category._id,
    });

    this.paticipants = this.allAssets;

    await this.getAssets({
      title: "Certifications",
      connectedTo: this.allTraining.category._id,
    });

    this.certifications = this.allAssets;
    await this.getAssets({
      title: "Testimonials",
      connectedTo: this.allTraining.category._id,
    });

    this.testimonials = this.allAssets;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.courseupcoming {
  padding: 40px 0;
  .courseupcominginner {
    .btncta {
      background-color: #3773ae;
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 10px;
      color: #fff;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .btncta:hover {
      background-color: #fff;
      color: #3773ae;
      border: 2px solid #3773ae;
    }
    .btncta1 {
      background-color: #fff;
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 10px;
      border: 2px solid #3773ae;
      color: #3773ae;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .btncta1:hover {
      background-color: #3773ae;
      color: #fff;
    }

    .subtitle {
      padding-top: 20px;

      h4 {
        font-size: 30px;
        padding: 0;
      }

      p {
        text-align: center;
        width: 60%;
        margin: 0 auto;
        padding: 15px 0;
      }

      .btncta2 {
        margin: 0 auto;
        background-color: transparent;
        display: block;
        width: 30%;
        text-align: center;
        padding: 15px 0;
        border-radius: 10px;
        border: 1px solid #3773ae;
        color: #3773ae;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.6px;
      }

      .btncta2:hover {
        background-color: #1876d2;
        color: #fff;
      }
    }

    .coursecard {
      border: 1px solid #ff9f00;
      border-radius: 10px;
      width: 600px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      gap: 30px;
      align-items: start;

      .datee {
        h6 {
          line-height: 22px;
          font-size: 32px;
          text-align: center;
          color: #ff9f00;
        }

        span {
          &:nth-child(2) {
            font-size: 18px;
            font-weight: normal;
          }
        }
      }

      .coursedetails {
        h5 {
          color: #363636;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;
          margin: 0 auto;
        }

        p {
          line-height: 26px;
        }

        ul {
          line-height: 26px;

          li {
            padding: 0px;
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
}

.courseupcoming {
  padding: 80px 0;

  .courseupcominginner {
    width: 100%;

    h4 {
      color: #363636;
      font-size: 36px;
      font-weight: bold;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase;
      margin: 0 auto;
      line-height: 44px;
    }
  }
}

.coursebenefit {
  background-color: #3773ae;
  padding: 80px 0;

  .coursebenefitinner {
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    h4 {
      color: #fff;
      font-size: 40px;
      font-weight: bold;
      padding-bottom: 30px;
      text-align: left;
      text-transform: uppercase;
      width: 30%;
    }

    ul {
      line-height: 30px;
      width: 70%;

      li {
        display: flex;
        margin: 0 10px;
        align-items: center;
        column-gap: 6px;
        color: #fff;
        font-size: 18px;
      }

      li:before {
        content: url(../../../assets/icons/icons-white.svg);
        display: block;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.coursefeatures {
  padding: 80px 0;

  .coursefeaturesinner {
    padding: 0;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .coursefeaturescontent {
    h4 {
      color: #363636;
      font-size: 32px;
      font-weight: 800;
      text-align: left;
      padding-bottom: 10px;
    }

    ul {
      line-height: 30px;
      padding: 0;
    }

    li {
      display: flex;
      margin: 0 10px;
      align-items: center;
      column-gap: 6px;
    }

    li:before {
      content: url(../../../assets/icons/icons.svg);
      // content:'';
      // background:url(../../../assets/icons/check.png) scroll 0 0 no-repeat;
      display: block;
      height: 20px;
      width: 20px;
    }
  }
}

.coursecontent {
  background-color: $colororange;
  width: 100%;
  padding: 80px 0;

  h4 {
    color: #fff;
    font-size: 32px;
    font-weight: 800;
    padding-bottom: 30px;
    text-align: center;
    text-transform: capitalize;
  }

  p {
    color: #363636;
    font-size: 14px;
  }

  .courseconttentfaq {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: center;

    .coursecontentcard {
      border: 1px solid #cdcdcd;
      border-radius: 10px;
      padding: 10px;
      background-color: #fff;

      .coursecontentcardin {
        display: flex;
        justify-content: start;
        column-gap: 10px;
        cursor: pointer;
      }

      h5 {
        color: rgb(65, 65, 65);
        font-size: 15px;
        font-weight: 600;
        padding: 5px 0;
        width: 100%;
      }

      p {
        color: #363636;
        font-size: 14px;
        padding: 0 15px 0 35px;
      }
    }
  }
}

.aboutbanner {
  h1 {
    color: #fff;
    font-size: 32px;
    font-weight: 800;
    padding-bottom: 20px;
  }

  ul {
    color: #fff;
  }

  li {
    margin: 0;
    font-size: 16px;
    line-height: 30px;
  }

  .v-icon {
    color: #fff;
    padding-right: 5px;
  }
}

.feturebox {
  padding: 0 0 80px 0;
  width: 100%;
  .logoscompany {
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: start;
    width: 100%;
  }
  .featurecard {
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    padding: 10px;

    h5 {
      color: rgb(65, 65, 65);
      font-size: 16px;
      padding-bottom: 5px;
    }

    p {
      color: #363636;
      font-size: 14px;
    }
  }
}

.formjoin {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px 30px;

  h5 {
    text-transform: uppercase;
    font-size: 24px;
    color: #363636;
  }
  small {
    display: block;
    padding-bottom: 10px;
  }

  button {
    background-color: #1876d2 !important;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 8px 20px !important;
    border-radius: 5px;
    border: 2px solid #4782da;
    margin-top: 10px !important;
    transition: 0.5s;
  }

  button:hover {
    color: #fff;
    border: 2px solid #4782da;
    background: transparent;
  }
}

.belowfold {
  padding: 40px 0 30px 0;
  h4 {
    color: #363636;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 20px;
  }

  .logoscompany {
    column-gap: 20px;
    .companyimg {
      padding: 10px;
      border: 1px solid #cdcdcd;
      border-radius: 10px;
      object-fit: contain;
      width: 95%;
      background: #fff;
      height: 130px;
    }
  }

  ul {
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 20px 0;
  }

  li {
    padding: 10px 15px;
    border: 1px solid red;
    border-radius: 5px;
  }
}
.trainingDescrption {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  ul {
    padding-left: 0 !important;
  }
}
.iconText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  @media only screen and (max-width: 991px) {
    justify-content: flex-start;
  }
  img {
    width: 60px;
    margin-right: 10px;
  }
}
.iconText2 {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-flow: column;
  text-align: left;
  padding-bottom: 10px;
  .v-icon {
    color: #f8c921;
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    height: auto;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }
  @media only screen and (max-width: 991px) {
    justify-content: flex-start;
  }
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: contain;
    border: 1px solid #d8d8d8;
  }
}

h4 {
  text-transform: capitalize;
}
.absModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}
.insideBox {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.headbanner {
  background-size: cover;
  height: 500px;
  width: 100%;
  background-position: center center;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .headbanner {
    height: auto !important;

    width: 100%;
  }
  .logoscompany {
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    justify-content: start;
    padding: 0 10px;
    margin-top: 25px;

    width: 100%;
  }
  .courseconttentfaq {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    justify-content: center;
  }
  .coursefeaturesinner {
    padding: 0;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .coursefeatures {
    padding: 40px 10px;
  }
  .courseupcominginner {
    h4 {
      font-size: 25px !important;
    }
    .btncta {
      font-size: 12px !important;
    }
    .btncta1 {
      font-size: 12px !important;
    }
  }
  .coursebenefitinner {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    h4 {
      font-size: 32px !important;
      text-align: center !important;
      width: 100% !important;
    }
    ul {
      width: 100% !important;
    }
  }
  .coursecard {
    width: 100% !important;
  }
  .belowfold {
    padding: 20px 0px !important;
    overflow: hidden;
    h4 {
      padding: 0;
      margin-bottom: -20px;
    }
  }
  .coursecontent {
    padding: 10px 0px;
    margin-top: 40px;
  }
  .cards {
    padding: 0 25px;
  }
  .coursecard {
    display: flex;
    flex-direction: column;
  }
  .insideBox[data-v-60f1d554] {
    width: 90%;
  }
  .booking-btn {
    display: flex;
    flex-direction: column !important;
    gap: 10px;
  }
}
@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headbanner {
    height: auto;
    width: 100%;
    background-position: center;
  }
  section {
    overflow: hidden;
  }
  .logoscompany {
    padding: 0 15px;
  }
}
</style>
