var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[(_vm.loading)?_c('div',[_c('v-container',{staticClass:"container-custom"},[_c('v-row',[_c('v-col',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}),_c('v-skeleton-loader',{staticClass:"banner-skeleton",attrs:{"type":"image"}}),_c('v-skeleton-loader',{staticClass:"banner-skeleton",attrs:{"type":"image"}}),_c('v-skeleton-loader',{staticClass:"banner-skeleton",attrs:{"type":"image"}}),_c('v-skeleton-loader',{staticClass:"banner-skeleton",attrs:{"type":"image"}}),_c('v-skeleton-loader',{staticClass:"banner-skeleton",attrs:{"type":"image"}})],1)],1)],1)],1):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"headbanner",style:(`background-image:url(${_vm.bannerUrl});`)},[_c('v-container',{staticClass:"container-custom aboutbanner"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 col banner-para"},[_c('h1',[_vm._v(_vm._s(_vm.training.category.title))]),_c('div',{staticClass:"trainingDescrption",domProps:{"innerHTML":_vm._s(_vm.training.results.description.slice(0, 650) + '...')}})]),_c('div',{staticClass:"col-md-5 col"},[_c('div',{staticClass:"formjoin"},[_c('h5',[_vm._v("Join Our Class")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Full Name","rules":[(v) => !!v || 'Name is required']},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Email","rules":[
                    ((v) => !!v || 'E-mail is required',
                    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'),
                  ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Phone","rules":[
                    (v) => !!v || 'Phone is required',
                    (v) =>
                      (v && v.length <= 10) ||
                      'Phone must be less than 10 characters',
                  ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-btn',{staticClass:"primary",staticStyle:{"height":"40px"},attrs:{"type":"button","loading":_vm.submitLoading},on:{"click":_vm.submitForm}},[_vm._v("Enroll Now")])],1)],1)])])])],1),_c('v-container',{staticClass:"container-custom belowfold"},[(_vm.recognizes.length > 0)?_c('h4',[_vm._v("Students are working At")]):_vm._e(),(_vm.certifications.length > 0)?_c('slick',{ref:"slick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.recognizes),function(data,index){return _c('div',{key:index,staticClass:"logoscompany"},[_c('img',{staticClass:"companyimg",attrs:{"src":data.image,"alt":""}})])}),0):_vm._e(),(_vm.certifications.length > 0)?_c('slick',{ref:"slickRev",staticClass:"reverseSlide",attrs:{"options":_vm.slickOptions1}},_vm._l((_vm.recognizes),function(data,index){return _c('div',{key:index,staticClass:"logoscompany"},[_c('img',{staticClass:"companyimg",attrs:{"src":data.image,"alt":""}})])}),0):_vm._e()],1),_c('v-container',{staticClass:"container-custom feturebox"},[_c('div',{staticClass:"logoscompany"},_vm._l((_vm.allTraining.category.attributes),function(data,index){return _c('div',{key:index,staticClass:"featurecard"},[_c('h5',[_vm._v(_vm._s(data.attributeKey))]),_c('p',[_vm._v(" "+_vm._s(data.attributeDesc)+" ")])])}),0)]),(_vm.paticipants.length > 0)?_c('v-container',{staticClass:"container-custom belowfold"},[_c('h4',[_vm._v("Tools Covered")]),(_vm.paticipants.length > 0)?_c('slick',{ref:"slick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.paticipants),function(data,index){return _c('div',{key:index,staticClass:"logoscompany"},[_c('img',{staticClass:"companyimg",attrs:{"src":data.image,"alt":""}})])}),0):_vm._e()],1):_vm._e(),(
        _vm.allTraining.results.courseContent &&
        _vm.allTraining.results.courseContent.length > 0
      )?_c('div',{staticClass:"coursecontent",staticStyle:{"background-color":"#4782da"}},[_c('v-container',{staticClass:"container-custom"},[_c('h4',[_vm._v("Course Content")]),_c('div',{staticClass:"courseconttentfaq"},_vm._l((_vm.allTraining.results.courseContent),function(data,index){return _c('div',{key:index,staticClass:"coursecontentcard"},[_c('div',{staticClass:"coursecontentcardin",on:{"click":function($event){_vm.faqActive != data.title
                  ? (_vm.faqActive = data.title)
                  : (_vm.faqActive = undefined)}}},[(_vm.faqActive != data.title)?_c('v-icon',[_vm._v(" mdi-plus ")]):_vm._e(),(_vm.faqActive == data.title)?_c('v-icon',[_vm._v(" mdi-minus ")]):_vm._e(),_c('h5',[_vm._v(_vm._s(data.title))])],1),(_vm.faqActive == data.title)?_c('div',{domProps:{"innerHTML":_vm._s(data.description)}}):_vm._e()])}),0)])],1):_vm._e(),(
        _vm.allTraining.results.courseObjects &&
        _vm.allTraining.results.courseObjects.length > 0
      )?_c('div',{staticClass:"coursefeatures"},[_c('v-container',{staticClass:"coursefeaturesinner container-custom"},[_c('div',{staticClass:"coursefeaturescontent"},[_c('h4',[_vm._v("Course Objectives")]),_c('ul',_vm._l((_vm.allTraining.results.courseObjects),function(data,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(data)+" ")])}),0)]),(
            _vm.allTraining.results.forWhom &&
            _vm.allTraining.results.forWhom.length > 0
          )?_c('div',{staticClass:"coursefeaturescontent"},[_c('h4',[_vm._v("Who ought to sign up HR Training ?")]),_c('ul',_vm._l((_vm.allTraining.results.forWhom),function(data,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(data)+" ")])}),0)]):_vm._e()])],1):_vm._e(),(_vm.allTraining.results.faqs && _vm.allTraining.results.faqs.length > 0)?_c('div',{staticClass:"coursecontent"},[_c('v-container',{staticClass:"container-custom"},[_c('h4',[_vm._v("Frequently Asked Question (FAQ)")]),_c('div',{staticClass:"courseconttentfaq"},_vm._l((_vm.allTraining.results.faqs),function(data,index){return _c('div',{key:index,staticClass:"coursecontentcard"},[_c('div',{staticClass:"coursecontentcardin",on:{"click":function($event){_vm.faqActive != data.title
                  ? (_vm.faqActive = data.title)
                  : (_vm.faqActive = undefined)}}},[(_vm.faqActive != data.title)?_c('v-icon',[_vm._v(" mdi-plus ")]):_vm._e(),(_vm.faqActive == data.title)?_c('v-icon',[_vm._v(" mdi-minus ")]):_vm._e(),_c('h5',[_vm._v(_vm._s(data.title))])],1),(_vm.faqActive == data.title)?_c('p',[_vm._v(" "+_vm._s(data.description)+" ")]):_vm._e()])}),0)])],1):_vm._e(),(_vm.certifications.length > 0)?_c('v-container',{staticClass:"container-custom belowfold"},[_c('h4',[_vm._v("Placement Partners")]),(_vm.certifications.length > 0)?_c('slick',{ref:"slick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.certifications),function(data,index){return _c('div',{key:index,staticClass:"logoscompany"},[_c('img',{staticClass:"companyimg",attrs:{"src":data.image,"alt":""}})])}),0):_vm._e(),(_vm.certifications.length > 0)?_c('slick',{ref:"slickRev",staticClass:"reverseSlide",attrs:{"options":_vm.slickOptions1}},_vm._l((_vm.certifications),function(data,index){return _c('div',{key:index,staticClass:"logoscompany"},[_c('img',{staticClass:"companyimg",attrs:{"src":data.image,"alt":""}})])}),0):_vm._e()],1):_vm._e(),(
        _vm.allTraining.results.benefits &&
        _vm.allTraining.results.benefits.length > 0
      )?_c('div',{staticClass:"coursebenefit",style:(`background-image:url(${_vm.benefitsUrl}); width:100%; background-size:cover; background-position:center; `)},[_c('v-container',{staticClass:"container-custom coursebenefitinner"},[_c('h4',[_c('span',[_vm._v("Benefit of ")]),_vm._v(" "),_c('span',[_vm._v("training")])]),_c('ul',_vm._l((_vm.allTraining.results.benefits),function(data,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(data)+" ")])}),0)])],1):_vm._e(),(_vm.testimonials.length > 0)?_c('v-container',{staticClass:"container-custom belowfold"},[_c('div',{attrs:{"width":"100%"}},[_c('slick',{ref:"slick",attrs:{"options":_vm.slickOptionss}},_vm._l((_vm.testimonials),function(dat,index){return _c('v-col',{key:index},[_c('div',{staticClass:"iconText2"},[_c('div',{staticStyle:{"border":"1px solid #d8d8d8","border-radius":"5px","padding":"6px","margin-bottom":"10px"}},[(dat.description)?_c('h5',[_vm._v(" "+_vm._s(dat.description)+" ")]):_vm._e()]),(dat.image)?_c('video',{staticClass:"mb-5",staticStyle:{"object-fit":"contain"},attrs:{"src":dat.image,"controls":"","height":"200","width":"100%"}}):_vm._e(),_c('div',{staticClass:"reviewImage",staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"reviewflex"},[_c('v-col',{attrs:{"md":"3"}},[_c('img',{attrs:{"src":dat.profileImage}})]),_c('v-col',{attrs:{"md":"9"}},[_c('p',[_vm._v(_vm._s(dat.fullName))]),_c('div',{staticClass:"innerstar"},_vm._l((dat.rating),function(rat,index){return _c('v-icon',{key:index},[_vm._v("mdi-star")])}),1)])],1)],1)])])}),1)],1)]):_vm._e(),_c('div',{staticClass:"courseupcoming"},[_c('PaymentForm',{attrs:{"percentage":_vm.percentage}})],1),(
        _vm.allTraining.trainingOthers && _vm.allTraining.trainingOthers.length > 0
      )?_c('v-container',{staticClass:"container-custom belowfold"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v("more classes by necojobs")])]),_vm._l((_vm.allTraining.trainingOthers),function(data,index){return _c('v-col',{key:index,staticClass:"py-0 cards",attrs:{"md":"4"}},[_c('Searchboxtraining',{staticClass:"jobslistBox",attrs:{"training":data}})],1)})],2)],1):_vm._e()],1):_vm._e(),_c('v-snackbar',{attrs:{"color":"success","bottom":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Your enquiry has been sent successfully ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }