<template>
  <section class="course-card">
    <div class="logobox">
      <router-link to="/"
        ><img :src="'https://app.necojobs.com.np' + training.imageUrl" alt=""
      /></router-link>
    </div>
    <div md="12">
      <ul class="jobdetail">
        <li class="jobtitle">
          <router-link :to="'/course/' + training.slug">{{
            training.trainingTitle
          }}</router-link>
        </li>
        <li class="companyName detailbox">
          <p v-html="training.description.slice(0, 120)"></p>
        </li>

        <li class="status" v-if="status">
          {{ status }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "SearchboxBlog",
  props: ["status", "training"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
section {
  div.row {
    background: $white;
    padding: 20px 0;
  }
  box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.status {
  background: $tagcolor;
  display: block;
  width: 120px;
  text-align: center;
  padding: 2px;
  margin-top: 5px;
  border-radius: 30px;
  color: $white;
}
.actionbutton {
  display: flex;
  justify-content: space-between;
  background: $white;
  a {
    text-decoration: none;
  }
  .v-icon {
    font-size: $iconfont;
    margin-left: 10px;
  }
  .socialshare {
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    padding: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
  }
  li {
    margin-left: 0;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    &:hover {
      .socialshare {
        display: flex;
        background: $white;
        flex-flow: row;
      }
    }
  }
}
.nomargin {
  li {
    margin-right: 0;
    margin-left: 15px;
  }
}
footer {
  border-top: 1px solid #cfcccc;
  padding: 10px 0 0;
}
.logobox {
  img {
    width: 100%;
    padding: 5px;
  }
}
.jobdetail {
  display: flex;
  margin: 0;
  flex-flow: column;
  li {
    margin-bottom: 5px;
    a {
      color: $tagcolor;
      text-decoration: none;
      font-weight: 500;
    }
  }
  .jobtitle {
    a {
      font-size: $iconfont;
    }
  }
  .companyName {
    margin-bottom: 10px;
    a {
      font-size: $normal_lfont;
    }
  }
  .location {
    font-size: $normalfont;
    .v-icon {
      font-size: $normal_lfont;
    }
  }
  padding: 10px;
}
ul {
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}
@media screen and (max-width: 600px) {
  .course-card {
    width: 100% !important;
    margin-top: 20px;
  }
}
</style>
